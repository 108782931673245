import { Root, Logo, ConsultationButton, FogEffect, MetaDiv } from "./styled";

// Todo
// 1. use animation library instead of css keyframes
// 2. add api for email
const Home = () => {
  return (
    <Root>
      <MetaDiv />
      <Logo />
      <ConsultationButton />
      <FogEffect />
    </Root>
  );
};

export default Home;
