import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
// import lowResBackground from "../../assets/backgroundLowResPng.jpg";
import lowResBackground from "../../assets/backgroundLowRes.jpg";
import logo from "../../assets/logo.png";
import fog_sprite_2 from "../../assets/fog_sprite.png";

export const Root = ({ children }) => {
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => setHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);

    const handleTouchMove = (e) => e.preventDefault();
    document.addEventListener("touchmove", handleTouchMove, { passive: false });

    const handleWheel = (e) => e.preventDefault();
    document.addEventListener("wheel", handleWheel, { passive: false });

    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("wheel", handleWheel);
    };
  }, []);

  return <Container style={{ height, position: 'fixed' }}>{children}</Container>;
};

export const Container = styled.div`
  background-image: url(${lowResBackground});
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
`;

export const Filter = styled.div`
  z-index: 9999;
  position: fixed;
  top: 0;
  opacity: 0.25;
  pointer-events: none;
  margin-top: 50vh;
  height: 50vh;
  width: 100vw;
  background: linear-gradient(transparent, black);
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10vh;
  width: 100%;
`;

const StyledImage = styled.img`
  max-width: 1000px;
  @media only screen and (max-width: 1000px) {
    max-width: 95vw;
  }

  @media only screen and (max-width: 800px) {
    margin-top: 50px;
  }

  @media only screen and (max-width: 700px) {
    margin-top: 50px;
  }
`;

export const MetaDiv = () => {
  return (
    <div meta='kanaka way' style={{ position: 'fixed', fontSize: '0', width: '1px', height: '1px' }}>
      <p meta='kanaka way'>Kanaka Way Design and Development Studio creates software such as web and mobile applications for clients of all sizes.</p>
      <h1 meta='kanaka way'>Kanaka Way Web Design & Development Studio</h1>
      <h2 meta='kanaka way'>Kanaka Way Web Design and Development Studio</h2>
      <p id='metaTag' meta='kanaka way'>
        HTML, CSS, JavaScript, PHP, Python, Ruby on Rails, ReactJS, AngularJS,
        VueJS, NodeJS. Java, C++, C#, Swift, Kotlin, TypeScript, Rust, Go, Dart,
        Lua..NET, PHP frameworks, Ruby frameworks, Python frameworks, JavaScript
        frameworks, front-end development, back-end development, full-stack
        development, software development, mobile app development.Website design
        services, website development services, website design agency, website
        development agency, kanaka way, kanakaway custom website design, professional website design,
        affordable website design, modern website design, responsive website
        design, mobile-friendly website design, e-commerce website design.
        Website design services, website development services, website design
        agency, website development agency, custom website design, professional
        website design, affordable website design, modern website design .
      </p>
    </div>
  );
};

export const Logo = () => {
  return (
    <LogoContainer>
      <StyledImage
        src={logo}
        alt="Kanaka Way Web Design & Development Studio"
      />
      <Filter />
    </LogoContainer>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 18vh;
`;

const StyledButton = styled.button`
  background: transparent;
  border: 3px solid white;
  color: white;
  padding: 5px 25px;
  font-weight: 900;
  transition-duration: 0.2s;
  font-size: 20px;
  &:hover {
    color: #816d5c;
    border-color: #2d343c;
    cursor: pointer;
    background-color: #2d343c;
  }
`;

export const ConsultationButton = () => {
  return (
    <ButtonContainer>
      <a href="mailto:info@kanakaway.com?subject=RE: Price consultation">
        <StyledButton>FREE CONSULTATION</StyledButton>
      </a>
      <div />
    </ButtonContainer>
  );
};

const createSlideAnimation = (translateX) => keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }

  10% {
    opacity: 100;
  }

  80% {
    opacity: 100;
  }

  100% {
    opacity: 0;
    transform: translate3d(${translateX}, 0, 0);
  }
`;

const FogSprite = styled.div`
  width: ${(props) => props.width};
  padding-left: ${(props) => props.offset};
  animation: ${(props) => createSlideAnimation(props.speed)} 30s linear infinite;
  position: absolute;
  bottom: 0px;
  pointer-events: none;
  height: 100vh;
`;

export const FogEffect = () => {
  return (
    <>
      <FogSprite speed="-1000px" width="1000px">
        <img
          style={{ height: "100vh" }}
          src={fog_sprite_2}
          alt="kanaka way"
        />
      </FogSprite>
      <FogSprite speed="-2500px" width="2500px" offset="500px">
        <img
          style={{ height: "100vh" }}
          src={fog_sprite_2}
          alt="kanaka way"
        />
      </FogSprite>
      <FogSprite speed="-1500px" width="1500px" offset="1000px">
        <img
          style={{ height: "100vh" }}
          src={fog_sprite_2}
          alt="kanaka way"
        />
      </FogSprite>
      <FogSprite speed="-2500px" width="2500px" offset="1500px">
        <img
          style={{ height: "100vh" }}
          src={fog_sprite_2}
          alt="kanaka way"
        />
      </FogSprite>
    </>
  );
};
